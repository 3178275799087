import { GetStaticProps } from 'next';

import { Error404View } from '@collab/organisms';
import { Redirect } from '@collab/organisms/ErrorView/types';

import { parseData } from 'libs/api';
import { ApiInfo } from 'libs/cms/common/types';
import PageSeo from 'libs/seo/PageSeo';

const ErrorPage = () => (
  <>
    <PageSeo title="404" />
    <Error404View redirects={siteRedirects} />
  </>
);

const getCoVeUrl = async () => {
  const response = await fetch('/api/apim/apis/public');
  const data = await parseData(response, 200);

  const coveInfo: ApiInfo | undefined = data.apis.find(
    (api: ApiInfo) => api.id === 'connected-vehicle-c3',
  );

  if (!coveInfo) {
    throw new ApiNotFoundError();
  }

  return `/apis/${coveInfo.slug}/v${coveInfo.latestVersion}/overview`;
};

const siteRedirects: Redirect[] = [
  {
    oldUrl: '/apis/connected-vehicle/v1',
    newUrl: getCoVeUrl,
    pageName: 'Connected Vehicle',
    message:
      'This version of the API has been removed as of April 30, 2024. Either explore the latest version or go to Home.',
  },
  {
    oldUrl: '/open-datasets',
    newUrl: '/resources',
    pageName: 'Resources',
  },
  {
    oldUrl: 'volvo-api',
    newUrl: '/apis',
    pageName: 'APIs',
  },
  {
    oldUrl: 'android-automotive/readme',
    newUrl: '/in-car-apps/android-emulator-xc40',
    pageName: 'Android emulator',
  },
  {
    oldUrl: 'android-automotive/sdk',
    newUrl: '/in-car-apps/android-emulator-xc40',
    pageName: 'Android emulator',
  },
  {
    oldUrl: 'android-automotive',
    newUrl: '/in-car-apps',
    pageName: 'In-car apps',
  },
];

class ApiNotFoundError extends Error {}

// This is needed to avoid server / client mismatch. No clue why..
export const getStaticProps: GetStaticProps = async () => ({ props: {} });

export default ErrorPage;
